<template>
  <div id="chose_goods">
    <div class="send_buy_block">
      <div class="title" v-if="isShow">
        快递地址停发的订单
        <span @click="$router.push('/member/send_order')">返回重新上传>></span>
        <a
          href="/business/member/stop_area"
          target="_blank"
          style="color: rgb(255, 70, 78); font-size: 5px"
          >点击查看快递停发区域</a
        >
      </div>
      <div class="excel_list_check" style="margin-bottom: 30px" v-if="isShow">
        <el-button
          type="danger"
          size="small"
          style="margin-top: 5px; margin-bottom: 5px"
          @click="deleteDataAkey()"
          >一键删除错误订单</el-button
        >
        <table>
          <tr>
            <td>#</td>
            <td>第三方订单编号</td>
            <td width="40px">收货人</td>
            <td>收货人手机号</td>
            <td width="200px">地址</td>
            <td width="200px">商品名</td>
            <td>校验结果</td>
            <td>操作</td>
          </tr>
          <tr v-for="(item, index) in list" v-if="item.isArea">
            <td>{{ index + 1 }}</td>
            <td>{{ item.orderNo }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
            <td>
              {{ item.province }}{{ item.city }}{{ item.county
              }}{{ item.address }}
            </td>
            <td>{{ item.tradeName }}</td>
            <td>
              <el-tooltip
                class="item"
                effect="dark"
                :content="getHtmlContent(item.html)"
                placement="top-end"
              >
                <div
                  style="
                    width: 4rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                  "
                  v-html="item.html"
                ></div>
              </el-tooltip>
            </td>
            <td class="handle">
              <p style="margin-bottom: 2px; margin-top: 2px">
                <el-button size="small" @click="showEdit(item, index)"
                  >编辑</el-button
                >
              </p>
              <p style="margin-bottom: 2px; margin-top: 2px">
                <el-button size="small" @click="del(index)" type="danger" plain
                  >删除</el-button
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="title">
        选择商品。
        <span @click="$router.push('/member/send_order')" v-if="!isShow"
          >返回重新上传>></span
        >
      </div>
      <div class="excel_list_check" v-if="shopCart1.name">
        <table>
          <tr>
            <td>商品名称</td>
            <td>单价</td>
            <td>采购数量</td>
            <td>单重量</td>
            <td>仓储</td>
            <td>小计</td>
          </tr>
          <tr>
            <td>
              <dl>
                <dt>
                  <div class="el-image">
                    <img :src="shopCart1.cover | fullPath" alt="" />
                  </div>
                </dt>
                <dd>{{ shopCart1.name }}</dd>
              </dl>
            </td>
            <td>￥{{ (shopCart1.cost + shopCart1.profit).toFixed(2) }}</td>
            <td>{{ shopCart1.num }}</td>
            <td>{{ shopCart1.weight }}</td>
            <td>{{ shopCart1.store }}</td>
            <td style="color: #ea242f">
              ￥{{
                (shopCart1.num * (shopCart1.cost + shopCart1.profit)).toFixed(2)
              }}
            </td>
          </tr>
        </table>
      </div>
      <div class="bt_item">
        <div class="left_item">
          <div class="remark">
            财务备注：<el-input v-model="remark" size="small"></el-input>
          </div>
          <p>
            总共有<b>{{ list.length }}</b
            >个 收件人，每人<b>￥{{ sumPrice }}</b
            >，一共需要支付<b>￥{{ (list.length * sumPrice).toFixed(2) }}</b>
          </p>
        </div>
        <div class="right_item">
          <div @click="showDialog">点击选择产品</div>
          <div
            class="topay"
            :class="shopCart1.name ? '' : 'dispaly'"
            @click="checkAreaSubmit"
          >
            选好了，去支付 ￥{{ (list.length * sumPrice).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="编辑收件人地址"
      width="600px"
      center
      :visible.sync="showTool"
    >
      <div class="input_put_block" v-if="showTool">
        <div class="put_item">
          <div class="item">
            <span>姓名:</span>
            <input type="text" v-model="editObj.name" placeholder="姓名" />
          </div>
          <div class="item">
            <span>电话:</span>
            <input type="text" v-model="editObj.phone" placeholder="电话" />
          </div>
          <div class="item">
            <span>省:</span>
            <input type="text" v-model="editObj.province" placeholder="省" />
          </div>
          <div class="item">
            <span>市:</span>
            <input type="text" v-model="editObj.city" placeholder="市" />
          </div>
          <div class="item">
            <span>区:</span>
            <input type="text" v-model="editObj.county" placeholder="区" />
          </div>
          <div class="item">
            <span>详细地址:</span>
            <input
              type="text"
              v-model="editObj.address"
              placeholder="详细地址"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="danger" @click="checkEdit"
          >确认修改</el-button
        >
        <el-button size="small" type="defauil" @click="showTool = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="添加商品" :visible.sync="dialogVisible" width="1200px">
      <div class="chose_goods clearfix">
        <div class="left_item">
          <div class="search_keywrod">
            <div class="chose red">商城产品</div>
          </div>
          <div class="goods_params">
            <div class="block clearfix" style="background-color: #ea242f;color: #fff;border-radius: 3px 3px 0px 0px">
              <div v-if="storeList!=null && storeList.length > 0" class="left_info" style="font-weight: bold">面单：<span style="font-weight: normal">{{  storeList[0].expressName}}</span></div>
            </div>
            <div class="block clearfix">
              <div class="left_info">仓储：</div>
              <ul>
                <li  @click="search(item),getExpressSheet(item.id)" :class="formData.storeId == item.id ? 'active':''" v-for="item in storeList">{{item.name}}

                </li>
              </ul>
            </div>
            <div class="block clearfix">
            </div>
            <div class="block clearfix">
            </div>
          </div>
          <div class="goods_list">
            <el-scrollbar style="height: 100%">
              <div
                class="item"
                v-for="item in goodsList"
                @click="addCart(item)"
              >
                <dl>
                  <dt>
                    <img
                      width="150px"
                      height="150px"
                      :src="item.cover | fullPath"
                      alt=""
                    />
                  </dt>
                  <dd class="title">
                    {{ item.name }}
                  </dd>
                  <dd class="other">
                    <span class="price"
                      >￥{{ (item.cost + item.profit).toFixed(2) }}</span
                    >
                    <span class="wei">{{ item.weight }} kg/件</span>
                  </dd>
                  <dd class="num">库存：{{ item.repertory }}件</dd>
                  <dd class="btn">加入购物车</dd>
                </dl>
              </div>
            </el-scrollbar>
          </div>
          <div class="home_fy_block wrap">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="formData.pageNo"
              :page-size="20"
              background=""
              layout="prev, pager, next,jumper,total"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="right_item">
          <div class="bk_item">
            <span>商品</span>
            <span>数量/重量</span>
            <span>价格</span>
            <span>操作</span>
          </div>
          <div class="item_list">
            <el-scrollbar v-if="shopCart.id">
              <div class="item clearfix">
                <div class="title">{{ shopCart.name }}</div>
                <dl class="clearfix">
                  <dt>
                    <img
                      width="50px"
                      height="50px"
                      :src="shopCart.cover | fullPath"
                      alt=""
                    />
                  </dt>
                  <dd>
                    <div class="jia" @click="subtractNum">-</div>
                    <div class="num">
                      <input
                        min="1"
                        @input="inputNum"
                        type="number"
                        v-model="shopCart.num"
                      />
                    </div>
                    <div class="jia" @click="addNum">+</div>
                    <div class="price">
                      ￥{{ Number(shopCart.cost + shopCart.profit).toFixed(2) }}
                    </div>
                    <div class="del" @click="clearShopCart">x</div>
                  </dd>
                  <dd>
                    <p>{{ shopCart.weight }}kg</p>
                    {{ shopCart.repertory }}库存
                  </dd>
                </dl>
                <div class="storage">
                  <span>{{ shopCart.store }}</span>
                </div>
              </div>
            </el-scrollbar>
            <div class="home_empty_list" v-else>
              <i class="el-icon-warning-outline"></i>
              暂无获取到相对应数据！
            </div>
          </div>
          <div class="item_b">
            <div class="b_title">下单后每个收件人将收到以上产品</div>
            <div class="price">
              <span>￥{{ goodsPrice }}</span>
              商品价格
            </div>
            <div class="weight">
              <span>￥{{ expressPrice }}</span>
              快递运费
            </div>
            <div class="total">
              <span>￥{{ sumPrice }}</span>
              总计：
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addGoods"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="新增店铺"
      :visible.sync="dialogVisible1"
      @close="closeDialog1"
      width="30%"
    >
      <el-form ref="form" :model="formData1" label-width="80px" size="medium">
        <el-form-item label="店铺类型">
          <el-select v-model="formData1.type" placeholder="请选择店铺类型">
            <el-option label="淘宝/天猫" value="2"></el-option>
            <el-option label="京东" value="3"></el-option>
            <el-option label="拼多多" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="formData1.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addHouse">确定</el-button>
          <el-button @click="dialogVisible1 = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="查看店铺列表(请购买服务后再进行授权)"
      :visible.sync="dialogVisible2"
      width="600"
    >
      <el-table :data="houseList" style="width: 100%">
        <el-table-column align="center" label="类型">
          <template slot-scope="scope">
            <p v-if="scope.row.type == 2">淘宝/天猫</p>
            <p v-if="scope.row.type == 4">拼多多</p>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="店铺名">
        </el-table-column>
        <el-table-column align="center" label="授权状态">
          <template slot-scope="scope">
            <p v-if="scope.row.status == 1">已授权</p>
            <p v-if="scope.row.status == 0">未授权</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="small" @click="buyServices(scope.row.type)"
              >购买服务</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.status == 0"
              @click="autoDeliver(scope.row.type, scope.row.id)"
              >授权</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.status == 1"
              @click="autoDeliver(scope.row.type, scope.row.id)"
              >重新授权</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="deleteHouse(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      showTool: false,
      editObj: {
        name: "",
        phone: "",
        html: "",
        state: "",
        province: "",
        city: "",
        county: "",
        address: "",
        index: "",
      },
      errorList: [],
      isShow: false,

      formData: {
        pageNo: 1,
        pageSize: 20,
        sort: "",
        storeId: "",
        type: "",
        expressCode:"",
        expressPlatform:"",
        shipperId:"",
        expressSheetId:"",
        tenantId: localStorage.getItem("tenantInfoid"),
      },
      formData1: {
        type: "",
        name: "",
      },
      shopCart: {},
      shopCart1: {
        name: "",
      },
      list: [],
      typeList: [],
      storeList: [],
      expressSheetList: [],
      goodsList: [],
      houseList: [],
      houseList1: [],
      total: 0,
      expressSheetId: "",
      expressSheetNameList: [
        "其他面单",
        "通用面单",
        "菜鸟面单",
        "拼多多面单",
        "京东面单",
      ],
      dialogVisible: false,
      goodsPrice: 0, //商品总价
      expressPrice: 0, //邮费
      sumPrice: 0, //总价 = 商品总价 + 邮费
      remark: "",
      houseId: "",
      dialogVisible1: false,
      dialogVisible2: false,
      platform: "",
      platformName:"",
      platformMap:{
        "3":"JD",
        "2":'TB',
        "4":'PDD',
        "5":"DY"
     },
      importType: "",
      shopId: "",
      goodsAllList:[],
      submitFlag: false,
      shipperId:''
    };
  },
  created() {
    let obj = sessionStorage.getItem("real_list_data");
    obj = JSON.parse(obj);
    this.list = obj.list;
    this.platform = obj.platform;
    this.shipperId=obj.shipperId;
    this.platformName = this.platformMap[obj.platform]
    this.importType = obj.importType;
    this.shopId = obj.shopId;
    this.getHouseList();
  },
  mounted() {},
  watch: {
    shopCart: {
      handler(newValue, oldValue) {
        this.countPrice();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getHtmlContent(html) {
      let htmles = html.split(">", 2);
      let htmless = htmles[1].split("<", 1);
      return htmless[0];
    },
    del(index) {
      this.$confirm("确定删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.list.splice(index, 1);

        let index1 = this.errorList.indexOf(index);
        this.errorList.splice(index1, 1);

        this.list.find((res) => {
          if (res.state == 0) {
            this.state = true;
            return true;
          } else {
            this.state = false;
          }
        });
      });
    },
    showEdit(item, index) {
      this.showTool = true;
      let obj = JSON.stringify(item);
      obj = JSON.parse(obj);
      this.editObj = obj;
      this.editObj.index = index;
    },
    checkEdit() {
      this.editObj.html = '<span style="color:#10b573">正确</span>';
      this.editObj.state = 1;
      this.editObj.phone =
        this.editObj.phone.indexOf("-") != -1
          ? this.editObj.phone.split("-")[1]
          : this.editObj.phone;
      if (!this.$check.isPhone(this.editObj.phone)) {
        this.editObj.html = '<span style="color:#ff464e">电话号码有误</span>';
        this.editObj.state = 0;
      }
      if (
        !this.editObj.province ||
        !this.editObj.city ||
        !this.editObj.county ||
        !this.editObj.address
      ) {
        this.editObj.html = '<span style="color:#ff464e">地址有误</span>';
        this.editObj.state = 0;
      }
      if (this.editObj.name == "") {
        this.editObj.html = '<span style="color:#ff464e">收货人不能为空</span>';
        this.editObj.state = 0;
      }
      if (this.editObj.state === 0) {
        if (this.errorList.indexOf(this.editObj.index) == -1) {
          this.errorList.push(this.editObj.index);
        }
      } else {
        let index = this.errorList.indexOf(this.editObj.index);
        if (index !== -1) {
          this.errorList.splice(index, 1);
        }
      }
      this.editObj.province = this.editObj.province.replace(/\s+/g, "");
      this.editObj.city = this.editObj.city.replace(/\s+/g, "");
      this.editObj.county = this.editObj.county.replace(/\s+/g, "");
      let item = JSON.stringify(this.editObj);
      this.list[this.editObj.index] = JSON.parse(item);
      this.editObj = {
        phone: "",
        html: "",
        state: "",
        province: "",
        city: "",
        county: "",
        address: "",
        index: "",
      };
      this.list.find((res) => {
        if (res.state == 0) {
          this.state = true;
          return true;
        } else {
          this.state = false;
        }
      });
      this.showTool = false;
    },
    getHouseList() {
      this.$request.post({
        url: "/ReportApi/business/shop/getShopInfo",
        success: (res) => {
          this.houseList = res;
          res.forEach((res) => {
            if (res.type == this.platform) {
              this.houseList1.push(res);
            }
          });
        },
      });
    },
    addHouse() {
      if (this.formData1.name == "") {
        this.$message.warning("请填写店铺名称");
        return;
      }
      if (this.formData1.type == "") {
        this.$message.warning("请选择店铺类型");
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/shop/addShop",
        params: {
          type: this.formData1.type,
          name: this.formData1.name,
        },
        success: (res) => {
          this.$message.success("添加成功");
          this.dialogVisible1 = false;
        },
      });
    },
    closeDialog1() {
      this.formData1 = {
        name: "",
        type: "",
      };
    },
    autoDeliver(type, id) {
      this.$request.post({
        url: "/ReportApi/business/shop/getAppId",
        params: {
          id,
        },
        success: (res) => {
          if (type == 2) {
            window.open(res);
          } else {
            window.open(res);
          }
        },
      });
    },
    deleteHouse(id) {
      this.$confirm("确定删除该店铺?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request
            .post({
              url: "/ReportApi/business/shop/deleteById",
              params: {
                id,
              },
              success: (res) => {
                this.$message.success("删除成功");
                this.getHouseList();
              },
            })
            .catch(function (error) {

            });
        })
        .catch(() => {});
    },
    buyServices(type) {
      if (type == 2) {
        window.open(



            "https://login.taobao.com/member/login.jhtml?f=top&sub=true&redirectURL=http%3A%2F%2Ffuwu.taobao.com%2Fser%2FassembleParam.htm%3Ftracelog%3Dsearch%26activityCode%3D%26promIds%3D%26subParams%3DitemCode%3AFW_GOODS-1000087962-1%2CcycleNum%3A6%2CcycleUnit%3A2%26qq-pf-to%3Dpcqq.c2c"
        );

      } else {
        window.open(
          "https://fuwu.pinduoduo.com/service-market/service-detail?detailId=434"
        );
      }
    },
    submit() {
      if (this.submitFlag) return;
      this.submitFlag = true;
      const loading = this.$loading();

      if (this.shopCart1.name == "") {
        this.submitFlag = false;
        loading.close();
        this.$message.warning("请选择商品");
        return;
      }
      let remark,
        shopId,
        recipientInfo = [];
      if (this.remark != "") {
        remark = this.remark;
      } else {
        remark = undefined;
      }
      if (this.shopId) {
        shopId = this.shopId;
      } else {
        shopId = undefined;
      }
      this.list.forEach((res) => {
        let obj = {
          orderNo: res.orderNo,
          name: res.name,
          phone: res.phone,
          province: res.province,
          city: res.city,
          county: res.county,
          address: res.address,
          tradeName: res.tradeName,
        };
        recipientInfo.push(obj);
      });
      recipientInfo = JSON.stringify(recipientInfo);
      let item1 = this.expressSheetList.find((res, index) => {
        if (res.id == this.expressSheetId) {
          return true;
        }
      });































      let str = "";




























      str = str + "商品一经快递发出，无法退款，请须知，是否确认支付？";
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$request.post({
            url: "/ReportApi/business/order/saveOrder",
            params: {
              belongTerrace: this.platform,
              expressSheetId: this.formData.expressSheetId,
              goodsId: this.shopCart1.id,
              goodsNum: this.shopCart1.num,
              importType: this.importType,
              recipientInfo,
              remark,
              shopId,
              tenantId: localStorage.getItem("tenantInfoid"),
              storeId: this.formData.storeId,
              expressCode:this.formData.expressCode,
              shipperId:this.shipperId
            },
            success: (res) => {
              this.$message.success("支付成功");
              this.$router.push("/member/order");
              this.getUserInfo();
            },
            finally: () => {
              this.submitFlag = false;
              loading.close();
            },
          });
        })
        .catch(() => {
          this.submitFlag = false;
          loading.close();
          this.$message.defaultMsg("取消支付");
        });
    },
    getUserInfo() {
      this.$request.post({
        url: "/ReportApi/business/user/getUserInfo",
        success: (res) => {
          if (res) {
            var arr = {};
            arr.info = res;

            if (res.levelId == 0) {
              arr.level = "普通会员";
            } else if (res.levelId == 1) {
              arr.level = "高级会员";
            } else {
              arr.level = "最高会员";
            }
            this.formData = {
              phone: arr.info.phone,
              wechat: arr.info.wechat,
              qq: arr.info.qq,
              email: arr.info.email,
            };
            this.$store.commit("getUserInfo", arr);
          }
        },
      });
    },












































    countPrice() {
      if (this.shopCart.num) {

        let weight = this.shopCart.weight * this.shopCart.num;
        let storeList = this.storeList;
        let item =null;


        if(this.formData.expressCode == ''){
          item =  storeList[0]
        }else{
          storeList.forEach( store => {
            if(this.formData.storeId ==store.id && store.expressId == this.formData.expressSheetId){
              item=store;
            }
          } )
        }









        if (item) {
          this.expressPrice = item.siteRealSellPrice;
        } else {
          let lastFlag = this.expressFreeInfo.list.length - 1;
          let lastItem = this.expressFreeInfo.list[lastFlag];
          weight = weight - lastItem.maxWeight;
          weight = Math.ceil(weight);
          this.expressPrice = (
              lastItem.price +
              weight * this.expressFreeInfo.risePrice
          ).toFixed(2);
        }
        let goodsPrice =
            (this.shopCart.cost + this.shopCart.profit) * this.shopCart.num;
        this.goodsPrice = goodsPrice.toFixed(2);
        let sumPrice = Number(this.expressPrice) + Number(this.goodsPrice);
        this.sumPrice = sumPrice.toFixed(2);
      } else {
        this.goodsPrice = 0;
        this.expressPrice = 0;
        this.sumPrice = 0;
      }
    },
    clearShopCart() {
      this.shopCart = {};
    },
    inputNum(e) {
      if (this.shopCart.num < 1) {
        this.shopCart.num = 1;
      }
    },
    addNum() {
      if (this.shopCart.num == 1) {
        this.$message.warning("购买数量不能大于1");
      } else {
        this.shopCart.num++;
      }
    },
    subtractNum() {
      if (this.shopCart.num == 1) {
        this.$message.warning("购买数量不能低于1");
      } else {
        this.shopCart.num--;
      }
    },






























    addCart(e) {




      let store = "";
      let stopStatus = 1;
      let stopReason = "";
      if (this.formData.storeId === "") {
        store = "全部仓储";
      } else {
        let temp = this.storeList.find((res) => {
          if (res.id == this.formData.storeId) {
            store = res.name;
            stopStatus = res.stopStatus;
            stopReason = res.stopReason;
            return true;
          }
        });
      }
      this.shopCart = Object.assign(
          { num: 1, store, stopStatus, stopReason },
          e
      );
    },
    search(item) {
      this.formData.pageNo = 1;

      this.formData.storeId = item.id
      this.formData.expressCode = item.expressCode
      this.formData.expressSheetId = item.expressId
      this.goodsAllList=item.goodsInfoVOS;
      this.getGoodsListV2(0)
    },
    showDialog() {
      let str = JSON.stringify(this.shopCart1);
      this.shopCart = JSON.parse(str);
      this.dialogVisible = true;
      if (this.formData.storeId === "") {
        this.getTypeList();
        this.getStoreList();
      }
      if (this.expressSheetList.length > 0) {
        if (
          this.platform == 2 ||
          this.platform == 4 ||
          this.platform == 3 ||
          this.platform == 5
        ) {
          for (let i = 0; i < this.expressSheetList.length; i++) {
            if (
              this.expressSheetList[i].expressStatus == 2 &&
              this.platform == 2
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
            if (
              this.expressSheetList[i].expressStatus == 3 &&
              this.platform == 4
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
            if (
              this.expressSheetList[i].expressStatus == 1 &&
              this.platform == 3
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
            if (
              this.expressSheetList[i].expressStatus == 2 &&
              this.platform == 5
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
          }
        }
      }
    },
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.formData.pageNo = val;
      this.getGoodsList();
    },
    addGoods() {
      if (this.shopCart.stopStatus === 1) {
        this.$alert(this.shopCart.stopReason, "此仓库暂停下单", {
          confirmButtonText: "确定",
          center: true,
          callback: (action) => {},
        });
      } else {
        this.dialogVisible = false;
        let str = JSON.stringify(this.shopCart);
        this.shopCart1 = JSON.parse(str);
      }
    },

    getTypeList() {
      this.$request.post({
        url: "/ReportApi/business/goodsType/getGoodsType",
        params: {},
        success: (res) => {
          this.typeList = res;
        },
      });
    },

    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/goodsStore/getStoreInfo",
        params: {
          expressPlatform: this.platformName,
        },
        success: (res) => {








          this.storeList = res;
          if (this.storeList.length > 0) {
            if (this.$store.state.goodsInfo.name) {
              this.formData.storeId = this.$store.state.goodsInfo.storeId;
            } else {
              this.formData.storeId = this.storeList[0].id;
            }
            this.getExpressSheet(this.formData.storeId);

            this.goodsAllList=res[0].goodsInfoVOS;
            this.getGoodsListV2(0)
            this.formData.expressCode =res[0].expressCode
            this.formData.storeId =res[0].storeId
            this.formData.expressSheetId =res[0].expressId
          }
        },
      });
    },
    getGoodsListV2(page,pageSize) {
      pageSize=20
      let data = this.goodsAllList;
      var rea = {};
      let  pageIndex = page*pageSize;
      let newData = [];
      for (let i = pageIndex; i < pageSize+pageIndex; i++) {
        if(i<data.length){
          data[i].cost = Number(data[i].cost);
          data[i].profit = Number(data[i].profit);
          data[i].weight = Number(data[i].weight);
          newData.push(data[i])
        }
      }
      rea.list = newData;
      rea.total = data.length;
      rea.page = 1;
      this.goodsList = rea.list;
      this.total = rea.total;
    },

    getGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goods/getGoodsInfo",
        params: this.formData,
        success: (res) => {
          this.goodsList = res.data;
          this.total = res.total;
        },
      });
    },

    getExpressSheet(storeId) {
      this.$request.post({
        url: "/ReportApi/business/expressSheet/expressSheet",
        params: {
          storeId: storeId,
        },
        success: (res) => {


          this.expressSheetList = res;
          if (this.expressSheetList.length > 0) {
            this.expressSheetId = this.expressSheetList[0].id;
            if (
              this.platform == 2 ||
              this.platform == 4 ||
              this.platform == 3 ||
              this.platform == 5
            ) {
              for (let i = 0; i < res.length; i++) {
                if (res[i].expressStatus == 2 && this.platform == 2) {
                  this.expressSheetId = res[i].id;
                  break;
                }
                if (res[i].expressStatus == 3 && this.platform == 4) {
                  this.expressSheetId = res[i].id;
                  break;
                }
                if (res[i].expressStatus == 1 && this.platform == 3) {
                  this.expressSheetId = res[i].id;
                  break;
                }
                if (res[i].expressStatus == 2 && this.platform == 5) {
                  this.expressSheetId = res[i].id;
                  break;
                }
              }
            }
            this.getExpressFree(this.expressSheetId);
          }
        },
      });
    },
    getExpressFree(expressSheetId) {
      this.$request.post({
        url: "/ReportApi/business/expressFree/getExpressFree",
        params: {
          expressSheetId: expressSheetId,
          tenantId: this.$store.state.userInfo.info.tenantId,
        },
        success: (res) => {

          let value = {
            list: [],
          };
          value.list = res.expressFee;
          this.expressFreeInfo = value;
          if (res.expressFee.length == 0) {
            this.$message.warning("此面单没有快递费用");
            this.shopCart = {};
            this.shopCart1 = {
              name: "",
            };
          }
          if (this.shopCart) {
            this.countPrice();
          }
        },
      });
    },
    deleteDataAkey() {
      this.$confirm("确定删除地址禁发的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].status == 0) {
            this.list.splice(i, 1);
            i--;
          }
        }
      });
    },
    checkAreaSubmit() {
      if (!this.formData.storeId) {
        return;
      }
      if (this.submitFlag) return;
      this.submitFlag = true;
      const loading = this.$loading();
      this.$request.post({
        url: "/ReportApi/business/goodsStore/getStopAreaInfo",
        params: {
          storeId: this.formData.storeId,
        },
        success: (res) => {
          let htm = '<span style="color:red">地区快递停发</span>';

          this.list.forEach((v1, index, array) => {
            if (res.length == 0 && this.list[index].html.indexOf(htm) != -1) {
              this.list[index].html =
                '<span style="color:#10b573">信息正确</span>';
              this.list[index].status = 1;
            } else {
              res.some((v2) => {
                if (
                  v2.areaType == 1 &&
                  v2.provinceName.indexOf(v1.province) != -1
                ) {
                  this.list[index].html = htm;
                  this.list[index].status = 0;
                  this.list[index]["isArea"] = true;
                  this.isShow = true;
                  this.$set(this.list, index, this.list[index]);
                  return true;
                } else if (
                  v2.areaType == 2 &&
                  v2.provinceName.indexOf(v1.province) != -1 &&
                  v2.cityName.indexOf(v1.city) != -1
                ) {
                  this.list[index].html = htm;
                  this.list[index].status = 0;
                  this.list[index]["isArea"] = true;
                  this.isShow = true;
                  this.$set(this.list, index, this.list[index]);
                  return true;
                } else if (
                  v2.areaType == 3 &&
                  v2.provinceName.indexOf(v1.province) != -1 &&
                  v2.cityName.indexOf(v1.city) != -1 &&
                  v2.expAreaName.indexOf(v1.county) != -1
                ) {
                  this.list[index].html = htm;
                  this.list[index].status = 0;
                  this.list[index]["isArea"] = true;
                  this.isShow = true;
                  this.$set(this.list, index, this.list[index]);
                  return true;
                } else {

                  this.list[index].html =
                    '<span style="color:#10b573">信息正确</span>';
                  this.list[index].status = 1;
                }
              });
            }
          });
          let item = this.list.find((arr) => {
            if (arr.status === 0) {
              return true;
            }
          });
          this.submitFlag = false;
          loading.close();
          if (item) {
            this.$message.error("快递地址停发，请尝试修改地址或者更换仓储！");
            return;
          }
          this.submit();
        },
        error: (res) => {
          this.submitFlag = false;
          loading.close();
        },
      });
    },
  },
};
</script>

<style lang="scss">
#chose_goods {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    margin-right: -16px !important;
    margin-bottom: 0px !important;
  }
}
.input_put_block {
  .put_item {
    span {
      display: inline-block;
      width: 80px;
      text-align: right;
      margin-right: 10px;
    }
    input {
      border: 1px solid #efefef;
      outline: none;
      width: 360px;
      line-height: 36px;
      padding: 0 5px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
#chose_goods {
  .send_buy_block {
    margin-bottom: 15px;
    background: #fff;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    display: block;
    border-radius: 5px;
    .title {
      font-size: 16px;
      font-weight: bold;
      span {
        color: #ea242f;
        cursor: pointer;
        font-size: 12px;
        margin-left: 20px;
      }
    }
    .packageList {
      margin-top: 20px;
    }
    .excel_list_check {
      margin-top: 20px;
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 12px;
        line-height: 30px;
        font-weight: normal;
        border: 1px solid #efefef;
        color: #666;
        tr {
          border-bottom: 1px solid #efefef;
          &:first-child {
            font-weight: bold;
            background: #f5f5f5;
            color: #333;
            line-height: 40px;
          }
        }
        td {
          border-right: 1px solid #efefef;
          padding: 0 10px;
        }
      }
      dt {
        float: left;
        margin-right: 10px;
      }
      dd {
        line-height: 25px;
      }
      dl {
        margin-top: 10px;
      }
      .el-image {
        width: 50px;
        height: 50px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        img {
          vertical-align: top;
          width: 100%;
          height: 100%;
        }
      }
    }
    .bt_item {
      font-size: 12px;
      margin-top: 20px;
      color: #666;
      padding: 20px 20px;
      background: #fafafa;
      display: flex;
      .left_item {
        flex: 1;
        .remark {
          display: flex;
          align-items: center;
          .el-input {
            flex: 0 0 215px;
          }
        }
        p {
          margin-top: 10px;
          color: #333;
          b {
            font-size: 14px;
            font-weight: bold;
            color: #ea242f;
          }
        }
      }
      .right_item {
        flex: 1;
        div {
          width: 200px;
          margin-right: 20px;
          line-height: 40px;
          text-align: center;
          color: #666;
          border: 1px solid #ea242f;
          color: #ea242f;
          font-size: 16px;
          float: left;
          cursor: pointer;
        }
        .topay {
          background: #ea242f;
          color: #fff;
        }
        .dispaly {
          background: #d9d9d9;
          color: #fff;
          border: none;
        }
      }
    }
  }
  .chose_goods {
    .left_item {
      width: 890px;
      float: left;
      height: 600px;
      overflow-x: hidden;
      .search_keywrod {
        display: flex;
        margin-bottom: 10px;
        div {
          flex: 1;
          margin-right: 10px;
        }
        .chose {
          flex: 0 1 140px;
          border-radius: 6px;
          border: 1px solid #efefef;
          line-height: 32px;
          text-align: center;
          &.red {
            color: #fff;
            background: #ea242f;
          }
        }
      }
      .goods_params {
        border: 1px solid #eee;
        border-radius: 5px;
        font-size: 14px;
        color: #666;
        .block {
          line-height: 50px;
          background: #fafafa;
          &:nth-child(1) {
            border-bottom: 1px solid #eee;
            background: #fff;
          }
          .left_info {
            float: left;
            padding-left: 20px;
            padding-right: 15px;
          }
          ul {
            float: left;
            li {
              float: left;
              padding: 0 10px;
              cursor: pointer;
              &.active {
                color: #ea242f;
                font-weight: bold;
              }
            }
          }
        }
      }
      .goods_list {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 355px;
        .item {
          width: 160px;
          border: 1px solid #eee;
          margin-bottom: 10px;
          margin-left: 10px;
          float: left;
          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }
          dl {
            dt {
              width: 150px;
              margin: 0 auto;
              margin-top: 8px;
            }
            dd {
              padding: 0 8px;
              font-size: 12px;
              color: #999;
              &.title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 20px;
              }
              &.other {
                height: 22px;
                .price {
                  color: #ff0036;
                  font-size: 14px;
                  font-weight: bold;
                  float: left;
                }
                .wei {
                  float: right;
                }
              }
              &.num {
                line-height: 25px;
                color: #333;
                border-top: 1px solid #efefef;
                border-bottom: 1px solid #efefef;
              }
              &.btn {
                border-radius: 5px;
                color: #ea242f;
                border: 1px solid #ea242f;
                line-height: 28px;
                width: 120px;
                text-align: center;
                margin: 10px auto;
                cursor: pointer;
                &:hover {
                  background: #ea242f;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .right_item {
      width: 250px;
      float: right;
      background: #dbdbdb;
      height: 600px;
      position: relative;
      .bk_item {
        display: flex;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        background: #333;
        span {
          flex: 1;
          text-align: center;
        }
      }
      .item_list {
        height: 420px;
        .item {
          background: #fff;
          border-left: 1px solid #efefef;
          border-right: 1px solid #efefef;
          padding: 4px 4px;
          margin-bottom: 6px;
          .title {
            line-height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 5px;
          }
        }
        dl {
          dt {
            float: left;
            border: 1px solid #efefef;
            margin-right: 5px;
          }
          dd {
            font-size: 12px;
            float: left;
            color: #666;
            width: 180px;
            .jia {
              float: left;
              width: 15px;
              height: 15px;
              line-height: 15px;
              text-align: center;
              border: 1px solid #efefef;
              margin-right: 5px;
              cursor: pointer;
            }
            .num {
              float: left;
              input {
                margin-right: 5px;
                border: 1px solid #efefef;
                width: 35px;
                height: 15px;
                line-height: 15px;
                text-align: center;
              }
            }
            .price {
              color: #ff0036;
              float: left;
              margin-left: 10px;
            }
            .del {
              float: right;
              font-size: 20px;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
            &:last-child {
              font-size: 12px;
              color: #999;
              p {
                color: #333;
                margin-right: 10px;
              }
            }
          }
        }
        .storage {
          clear: both;
          margin-top: 4px;
          span {
            color: #ea242f;
            border: 1px solid #ea242f;
            margin-right: 5px;
            border-radius: 2px;
            padding: 2px 2px;
            margin-bottom: 4px;
            float: left;
            font-size: 12px;
          }
        }
      }
      .home_empty_list {
        text-align: center;
        color: #666;
        line-height: 200px;
      }
      .item_b {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        height: 180px;
        color: #666;
        line-height: 25px;
        .b_title {
          background: #333;
          color: #fff;
          width: 230px;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 5px;
          line-height: 25px;
          text-align: center;
          margin-bottom: 10px;
        }
        .price {
          padding: 0 20px;
          span {
            float: right;
            color: #ff0036;
          }
        }
        .weight {
          padding: 0 20px;
          span {
            float: right;
            color: #ff0036;
          }
        }
        .total {
          padding: 0 20px;
          span {
            float: right;
            font-size: 20px;
            font-weight: bold;
            color: #ff0036;
          }
        }
      }
    }
  }
}
</style>
